<template>
  <div>
    <div class="user_undergo_box">
      <div class="user_undergo user_undergo_a">暂扣物：{{ info.ZKW }}</div>
      <div class="user_undergo user_undergo_a">物品数量：{{ info.ZKW_SL }}</div>
      <div class="user_undergo user_undergo_a">劝导结果：{{ info.QDJG }}</div>
      <div class="user_undergo user_undergo_a">
        劝导后照片：
      </div>
      <img
        v-for="(item, i) in info.FileListEnd"
        :key="i"
        :src="item.Url"
        style="width: 80px;height:80px;display: inline-block;padding: 2px"
        @click="previewEnd"
      />
    </div>
  </div>
</template>

<script>
  import { ImagePreview } from 'vant'

  export default {
    name: 'AdviseResult',
    props: {
      info: {
        type: Object,
        default: () => {},
      },
      fileListEnd: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {}
    },
    methods: {
      previewEnd() {
        ImagePreview({
          images: this.fileListEnd,
        })
      },
    },
  }
</script>

<style scoped>
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
</style>
