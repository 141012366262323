<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="formData.ZKW"
        label="暂扣物"
        placeholder="请填写暂扣物"
      />
      <van-field
        v-model="formData.ZKW_SL"
        label="暂扣物数量"
        placeholder="请填写暂扣物数量"
      />
      <van-field
        v-model="formData.QDJG"
        rows="4"
        autosize
        label="劝导结果"
        type="textarea"
        placeholder="请填写劝导结果"
        :rules="[{ required: true, message: '请填写劝导结果' }]"
      />
      <van-field label="照片上传" />
      <upload-file :limit="9" @fetch-data="getFileList" />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import UploadFile from '@/components/UploadFile'
  import { adviseReply } from '@/api/workBench/advise/advise'
  import store from '@/store'
  import { Toast } from 'vant'

  export default {
    name: 'AdviseReply',
    components: { UploadFile },
    props: {
      id: {
        type: Number,
        default: () => 0,
      },
    },
    data() {
      return {
        fileList: [],
        formData: {
          Advise_ID: this.id,
          ZKW: '',
          ZKW_SL: '',
          QDJG: '',
          File: '',
          User_ID: 0,
        },
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.formData.User_ID = userInfo.User_ID
    },
    methods: {
      async onSubmit() {
        this.formData.File =
          this.fileList.length > 0 ? this.fileList.join('|') : ''

        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { data } = await adviseReply(this.formData)
        this.$emit('reply')
        Toast.clear()
      },

      getFileList(val) {
        this.fileList = val
      },
    },
  }
</script>

<style scoped></style>
