<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">劝导单信息</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="job_describe_cengter_header">{{ info.QDDX_MC }}</div>

          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              劝导类型：{{ info.QDDX_LX }}
            </div>
            <div class="user_undergo user_undergo_a">
              执法类型：{{ info.ZFLB_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              处理结果：{{ info.CLJG_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              劝导前照片：
            </div>
            <img
              v-for="(item, i) in info.FileListBefore"
              :key="i"
              :src="item.Url"
              style="width: 80px;height:80px;display: inline-block;padding: 2px"
              @click="previewBefore"
            />
            <div class="user_undergo user_undergo_a">
              所属中队：{{ info.ZD_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              所属网格：{{ info.WG_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              发起人员：{{ info.Staff_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              协调人员：{{ info.XTRY }}
            </div>
          </div>
        </div>
      </div>
      <div class="corporate_information">
        <div class="job_describe_top">
          <div class="jjxx">劝导后结果</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter2">
          <advise-reply
            v-if="info.This_Status === 1"
            :id="info.ID"
            @reply="reply"
          />
          <advise-result
            v-if="info.This_Status === 2"
            :info="info"
            :file-list-end="fileListEnd"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import { Toast } from 'vant'
  import { ImagePreview } from 'vant'
  import { getAdviseInfo } from '@/api/workBench/advise/advise'
  import adviseReply from '@/views/workBench/advise/components/adviseReply'
  import adviseResult from '@/views/workBench/advise/components/adviseResult'
  import { Emas } from '@/assets/js/Emas'
  import store from '@/store'

  export default {
    name: 'EmploymentInfo',
    components: { adviseReply, adviseResult },
    data() {
      return {
        advise_ID: 0,
        systemUrl,
        info: {}, //详情数据
        fileListBefore: [],
        fileListEnd: [],
      }
    },
    created() {
      this.advise_ID = Base64.decode(this.$route.query.advise_ID)
      this.fetchData()
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'adviseInfo',
        '城管-劝导详情',
        'zzdcg.yy.gov.cn/adviseInfo'
      )
    },

    methods: {
      //获取劝导详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getAdviseInfo({ Advise_ID: this.advise_ID })
        let fileListBefore = data.FileListBefore
        if (fileListBefore.length > 0) {
          for (let i = 0; i < fileListBefore.length; i++) {
            fileListBefore[i].Url = systemUrl + fileListBefore[i].Url
            this.fileListBefore.push(fileListBefore[i].Url)
          }
        }
        let fileListEnd = data.FileListEnd
        if (fileListEnd.length > 0) {
          for (let i = 0; i < fileListEnd.length; i++) {
            fileListEnd[i].Url = systemUrl + fileListEnd[i].Url
            this.fileListEnd.push(fileListEnd[i].Url)
          }
        }
        data.FileListBefore = fileListBefore
        data.FileListEnd = fileListEnd
        this.info = data
        Toast.clear()
      },

      previewBefore() {
        ImagePreview({
          images: this.fileListBefore,
        })
      },

      reply() {
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
